import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Button, TablePagination, Box, Paper, Modal, IconButton, FormControl, InputLabel, MenuItem, Chip, Select, OutlinedInput, CircularProgress } from "@mui/material";
import * as Action from "../../actions/adminAction"
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from '@mui/icons-material/Send';
import React, { useEffect, useState } from 'react';
// import { Delete } from '@mui/icons-material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const AssignDistrictToDC = ({ getAllDistrictData, AssignDistrictToQCData, userDetails, getDistrictCollectorList }) => {

    const classes = useStyles()
    const [loader, setLoader] = useState(false)

    //data variables
    const [QCList, setQCList] = useState([])
    const [SelectedQC, setSelectedQC] = useState('')
    const [SelectedDistrictArray, setSelectedDistrictArray] = useState([])
    const [DistrictList, setDistrictList] = useState([])

    //Modal variables
    const [DistrictModal, setDistrictModal] = useState(false)

    //Table Pagination variables
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    //Table Pagination Functions
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    useEffect(() => {
        getQC()
        getDistricts()
    }, [])

    const getQC = async () => {
        setLoader(true)
        const result = await getDistrictCollectorList()
        if (result) {
            // console.log(result)
            setQCList(result)
            setLoader(false)
        }else{
            setLoader(false)
        }
    }

    const getDistricts = async () => {
        const result = await getAllDistrictData()
        if (result) {
            setDistrictList(result)
        }
    }

    const openModal = (row) => {
        setSelectedQC(row)
        setDistrictModal(true)
    }

    const handleChangeDistrict = (event) => {
        const {
            target: { value },
        } = event
        setSelectedDistrictArray(
            typeof value.districtName === 'string' ? value.split(',') : value
        )
    }

    const handleAssign = () => {

        let distric_idArr = []
        let distric_NameArr = []

        SelectedDistrictArray.map((row) => {
            distric_idArr.push(row?._id)
        })

        SelectedDistrictArray.map((row) => {
            distric_NameArr.push(row?.districtName)
        })

        AssignDistrict(distric_idArr, distric_NameArr)
    }

    const AssignDistrict = async (IdArr, NameArr) => {
        // console.log("Selected QC", SelectedQC)
        // console.log("Selected Districts IDs", IdArr)
        // console.log("Selected Districts Names", NameArr)
        const result = await AssignDistrictToQCData({
            "user_id": SelectedQC?._id,
            "assignDistrict_id": IdArr,
            "assignDistrictName": NameArr,
            "updatedBy": userDetails?._id
        })
        if (result) {
            // console.log("Assign District", result)
            setDistrictModal(false)
            setSelectedDistrictArray([])
            getQC()
        }
    }

    return (
        <div className={classes.main}>
            <center>
                <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }} >Assign District To District Co-ordinator</Typography>
            </center>
            {loader
                ? <CircularProgress color='primary' />
                : <Paper sx={{ overflow: 'hidden', }}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ textAlign: 'center', fontSize: 16, fontWeight: 600 }}>District Co-ordinator Name</TableCell>
                                    <TableCell style={{ textAlign: 'center', fontSize: 16, fontWeight: 600 }}>Contact No</TableCell>
                                    <TableCell style={{ textAlign: 'center', fontSize: 16, fontWeight: 600 }}>Assigned District Count</TableCell>
                                    <TableCell style={{ textAlign: 'center', fontSize: 16, fontWeight: 600 }}>All Assigned District</TableCell>
                                    <TableCell style={{ textAlign: 'center', fontSize: 16, fontWeight: 600 }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {QCList !== 'No data found' && QCList.length > 0 ?
                                    QCList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        return (
                                            <TableRow key={row?._id}>
                                                <TableCell style={{ textAlign: 'center' }}>{row?.firstName} {row?.lastName}</TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>{row?.contactNo}</TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>{row?.assignDistrict_id.length !== 0 ? row?.assignDistrict_id.length : '-'}</TableCell>
                                                {/* <TableCell style={{ textAlign:'center', }}>{row?.assignDistrictName.toString()}</TableCell> */}
                                                <TableCell style={{ textAlign: 'center', }}>
                                                    {/* <InputLabel shrink htmlFor="select-multiple-native">
                                                        All Assigned District
                                                    </InputLabel> */}
                                                    <Select
                                                        native
                                                        multiple
                                                        // inputProps={{
                                                        //     id: 'select-multiple-native',
                                                        // }}
                                                        
                                                    >
                                                        {
                                                            row?.assignDistrictName.length > 0 ? row?.assignDistrictName.map((item) => (
                                                                <option key={item}>
                                                                    {item}
                                                                </option>
                                                            )) : 'No Data found'
                                                        }
                                                    </Select>
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>
                                                    <Button variant='contained' onClick={() => openModal(row)}>
                                                        Assign Districts
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                    : <TableRow>No Data Found</TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        // count={count ? count : 0}
                        count={ QCList !== "No data found" && QCList?.length > 0 ? QCList?.length : 0 }
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Paper>}
            <Modal
                className={classes.middlePosition}
                open={DistrictModal}
                onClose={(e) => {
                    e.preventDefault();
                    setDistrictModal(false)
                }}
            >
                <Paper className={classes.passmodal}>
                    <Stack direction="row" justifyContent="space-between"
                        alignItems="center" spacing={2}>
                        <Stack direction="column">
                            <Typography variant='h6' component='div'>Assign District to DC</Typography>
                        </Stack>
                        <IconButton aria-label="delete" onClick={e => {
                            e.preventDefault();
                            setDistrictModal(false)
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 20 }}>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="demo-multiple-chip-label">Select Multple Districts</InputLabel>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={SelectedDistrictArray}
                                onChange={handleChangeDistrict}
                                input={<OutlinedInput id="select-multiple-chip" label="Select Multple Districts" />}
                                renderValue={(Selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {
                                            Selected.map((item) => (
                                                <Chip 
                                                key={item?._id}
                                                 label={item?.districtName}  
                                                 />
                                            ))
                                        }
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {DistrictList !== 'No data found' && DistrictList.length > 0 ?
                                    DistrictList.map((row) => {
                                        return (
                                            <MenuItem
                                                key={row?._id}
                                                value={row}
                                            >
                                                {row?.districtName}
                                            </MenuItem>
                                        )
                                    })
                                    : null}
                            </Select>
                        </FormControl>
                        <center>
                            <Button variant='contained' style={{ margin: 10 }} onClick={() => handleAssign()} disabled={SelectedDistrictArray.length === 0} ><SendIcon style={{ margin: 5 }} />Submit</Button>
                        </center>
                    </div>
                </Paper>
            </Modal>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column',
        marginTop: 30
    },
    card: {
        width: '1200px',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    passmodal: {
        width: '350px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    profilemodal: {
        width: '1000px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    middlePosition: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    input: {
        width: '70%',
        background: 'white',
        marginTop: '20px',
        padding: '5px 15px'
    },
    image: {
        width: 150,
        height: 150
    },
    imageApprove: {
        backgroundColor: 'green',
        color: 'white'
    },
    imageReject: {
        backgroundColor: 'red',
        color: 'white'
    }
}));

const mapStateToProps = (state) => {
    return {
        userDetails: state.auth.userDetails,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllDistrictData: () => dispatch(Action.getAllDistrictData()),
        AssignDistrictToQCData: (data) => dispatch(Action.AssignDistrictToQCData(data)),
        getDistrictCollectorList: () => dispatch(Action.getDistrictCollectorList()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignDistrictToDC)