import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Button, TablePagination, Paper, TextField, FormControl} from "@mui/material";
import { CircularProgress } from '@mui/material';
import * as Action from "../../actions/adminAction"
// import Stack from "@mui/material/Stack";
// import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
// import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
// import { Edit, Visibility, Delete } from "@mui/icons-material";
import React, { useState } from 'react';
import { BASE_URL } from '../../utils/urls'
import dayjs from "dayjs";
// import { LoadScriptNext } from '@react-google-maps/api';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';


export const RejectedTrainer = ({ userDetails,
  getAllDatewiseAssignedTrainer1,
  getAllDistrictData,
  DistrictWisePanchayat,
  getAllDistrictwiseAssignedTrainer1 }) => {

  // console.log("Rollout UserID::::", userDetails)
  const classes = useStyles()
  const navigate = useNavigate()

  //data variables
  const [startDate, setstartDate] = useState(new Date())
  const [endDate, setendDate] = useState(new Date())
  const [cityData, setcityData] = useState([]);
  const [PanchayatData, setPanchayatData] = useState([]);
  const [panchayat, setPanchayat] = useState("");
  const [city, setCity] = useState("");
  const [prefferedDist, setPrefferedDist] = useState(userDetails.preferredDistrictName)
  const [prefferedDistId, setPrefferedDistId] = useState(userDetails.preferredDistrict_id)

  // Error variables
  const [dateError, setdateError] = useState(false)
  const [InvalidDate, setInvalidDate] = useState(false)
  const [cityError, setcityError] = useState(false);
  const [PanchayatError, setPanchayatError] = useState(false);
  const [DisPanError, setDisPanError] = useState(false)

  const [trainerData, setTrainerData] = useState([])
  const [loader, setLoader] = useState(false)
  const [filteredData, setFilteredData] = useState()
  const [filterString, setFilterString] = useState()

  //Table Pagination variables
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //Modal Variables
  const [assign, setAssign] = useState(false)

  React.useEffect(() => {
    getDistrict();
    fetchRolloutPlan();
  }, [])

  React.useEffect(() => {
    if (city) {
      getPanchayat()
    }
  }, [city])

  const getDateWise = async () => {
    setLoader(true)
    setdateError(false)
    setInvalidDate(false)
    if (endDate < startDate) {
      // console.log("Invalid")
      setLoader(false)
      setInvalidDate(true)
    } else {
      if (startDate !== null && endDate !== null) {
        const result = await getAllDatewiseAssignedTrainer1({
          start: moment(startDate.$d).format("YYYY-MM-DD"),
          end: moment(endDate.$d).format("YYYY-MM-DD"),
          trainer_id: userDetails?._id
        })
        if (result) {
          setTrainerData(result)
          setLoader(false)
        } else {
          setLoader(false)
        }
      } else {
        setLoader(false)
        setdateError(true)
      }

    }

  }

  const getDistrictWise = async () => {
    setDisPanError(false)
    if ((1 + city) && panchayat) {
      const result = await getAllDistrictwiseAssignedTrainer1({
        distric_id: city?._id,
        grampanchayat_id: panchayat?._id,
        trainer_id: userDetails?._id
      })
      if (result) {
        setTrainerData(result)
        setLoader(false)
      } else {
        setLoader(false)
      }
    } else {
      setDisPanError(true)
    }
  }


  const getDistrict = async () => {
    const result = await getAllDistrictData();

    if (result !== "No data found") {
      setcityData(result);
    }
  };

  const getPanchayat = async (Id) => {
    const result = await DistrictWisePanchayat({
      "distric_id": city?._id
    })
    if (result) {
      setPanchayatData(result)
    }
  }

  const fetchRolloutPlan = () => {
    setLoader(true);
    let header = new Headers();
    header.append("Content-Type", "application/x-www-form-urlencoded")
    let urlencoded = new URLSearchParams();
    urlencoded.append("trainer_id", userDetails?._id);
    const opt = {
      method: 'POST',
      headers: header,
      body: urlencoded,
    }
    fetch(BASE_URL + "/trainingassigntrainer/trainingassigntrainer/fortrainer", opt)
      .then(res => {
        return res.json()
      })
      .then(data => {
        // console.log("Rollout plan", data);
        // console.log("TODAY DATE____-----******^^^^^", dayjs(new Date()).format("DD-MM-YYYY"))
        setTrainerData(data)
        setFilteredData(data)
        setLoader(false);
      })
      .catch(err => {
        // console.log("Error", err);
        setLoader(false);
      })
  }

  //Table Pagination Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filterData = (char) => {
    setLoader(true);
    // console.log(char)
    // console.log("Charracter Array", filteredData)
    let f = filteredData?.filter(item => item?.trainerNameAll[0]?.toLowerCase()?.startsWith(char?.toLowerCase()) ||
      item?.trainerNameAll[1]?.toLowerCase()?.startsWith(char?.toLowerCase()) ||
      item?.trainerMobileAll[0]?.toString()?.startsWith(char?.toLowerCase()) ||
      item?.trainerMobileAll[1]?.toString()?.startsWith(char?.toLowerCase()) ||
      item?.districtName?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
      item?.grampanchayatName?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
      dayjs(item?.startDate).format('DD-MM-YYYY').toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
      item?.batch?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
      item?.trainingModuleName?.toString().toLowerCase()?.startsWith(char?.toLowerCase()))
    setTrainerData(f)
    // console.log("MyData", f)
    setLoader(false);
  }

  return (
    <div className={classes.main} >
      <div className={classes.main}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%' }}>
          <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }}>Pending Document</Typography>
          {/* <Button variant='contained' onClick={() => { }} style={{ margin: 5 }} >View</Button> */}
        </div>
        <FormControl sx={{ m: 1, marginRight: 3 }}>
          <TextField
            style={{ width: 300 }}
            // error={TrainerName.match(/[0-9+@#$&%!~]/)}
            id="outlined-adornment-TrainerName"
            label="Filter"
            value={filterString}
            onChange={(e) => { setFilterString(e.target.value); filterData(e.target.value) }}
          // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
          />
        </FormControl>
      </div>
      {/* <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ margin: 5, display: 'flex', flexDirection: 'column' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => {
                setstartDate(newValue);
              }}
              inputFormat={"DD/MM/YYYY"}
              renderInput={(params) => <TextField  {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div style={{ margin: 5, display: 'flex', flexDirection: 'column' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              value={endDate}
              minDate={startDate}
              onChange={(newValue) => {
                setendDate(newValue);
              }}
              inputFormat={"DD/MM/YYYY"}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div style={{ margin: 5, display: 'flex', flexDirection: 'column' }}>
          <Button variant='contained' style={{ margin: 5, width: 170, height: 50 }} onClick={() => getDateWise()}>Get Data Datewise</Button>
          {dateError ? <span style={{ color: 'red' }}>*Please Select Both Dates</span> : null}
          {InvalidDate ? <span style={{ color: 'red' }}>*End Date cannot be less than Start date</span> : null}

        </div>
        <Button variant='contained' style={{ margin: 10, width: 130, height: 50 }} onClick={() => { fetchRolloutPlan(); }} >GET All Data</Button>
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
        <FormControl sx={{ m: 1, marginRight: 1 }}>
          <InputLabel id="outlined-adornment-Bank">District</InputLabel>
          <Select
            style={{ width: 200 }}
            id="outlined-adornment-Bank"
            label="District"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
              // getPanchayat(e.target.value)
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
          >
            {cityData &&
              cityData.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    {item?.districtName}
                  </MenuItem>
                );
              })}
          </Select>
          {cityError ? (
            <span style={{ color: "red", fontSize: 15 }}>
              *Please Select District
            </span>
          ) : null}
        </FormControl>
        <FormControl sx={{ m: 1, marginRight: 1 }}>
          <InputLabel id="outlined-adornment-Bank">Panchayat</InputLabel>
          <Select
            style={{ width: 200 }}
            id="outlined-adornment-month"
            label="Panchayat"
            value={panchayat}
            disabled={PanchayatData?.length === 0}
            onChange={(e) => {
              setPanchayat(e.target.value);
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
          >
            {PanchayatData &&
              PanchayatData?.map((item) => {
                return (
                  <MenuItem key={item?._id} value={item}>
                    {item?.grampanchayatName}
                  </MenuItem>
                );
              })}
          </Select>
          {PanchayatError ? (
            <span style={{ color: "red", fontSize: 15 }}>
              *Please Select Panchyat
            </span>
          ) : null}
        </FormControl>
        <div style={{ margin: 5, display: 'flex', flexDirection: 'column' }}>
          <Button variant='contained' style={{ margin: 10, height: 50, width: 180 }} onClick={() => getDistrictWise()} >Get Panchayat wise data</Button>
          {DisPanError ? <span style={{ color: 'red', flexWrap: 'wrap' }}>*Please Select Both District and Grampanachayat</span> : null}
        </div>
      </Box> */}
      {loader ? <CircularProgress color='primary' /> : <Paper sx={{ overflow: 'hidden', width: '90%' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Sr. No.</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>District</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Training Location</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Training Date</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Batch</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Module</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Action</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                trainerData.length > 0 && trainerData !== 'No data found' ? trainerData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{index + 1}</TableCell>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.districtName}</TableCell>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.grampanchayatName}</TableCell>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{moment(row?.startDate).format("DD MMM YYYY")}</TableCell>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.batch ? row?.batch?.toUpperCase() : '-'}</TableCell>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.trainingModuleName}</TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <Button variant='contained' onClick={() => navigate('/trainerLayout/viewRejectedItem', { state: row })} style={{ margin: 5 }} >View</Button>
                        {/* <Button variant='contained' onClick={() => navigate('/trainerLayout/uploadDocument', { state: row })} style={{ margin: 5 }} >Upload Document</Button> */}
                      </TableCell>
                    </TableRow>
                  )
                })
                  : <TableRow>NO Data Found</TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          // count={count ? count : 0}
          count={trainerData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>}

    </div>
  )
}


const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column',
    marginTop: 30
  },
  card: {
    width: '1200px',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  passmodal: {
    width: '350px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  profilemodal: {
    width: '1400px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  middlePosition: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  input: {
    width: '70%',
    background: 'white',
    marginTop: '20px',
    padding: '5px 15px'
  },
}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getAllAssignedTrainer: () => dispatch(Action.getAllAssignedTrainer()),
    getAllDatewiseAssignedTrainer1: (data) => dispatch(Action.getAllDatewiseAssignedTrainer1(data)),
    getAllDistrictData: () => dispatch(Action.getAllDistrictData()),
    DistrictWisePanchayat: (data) => dispatch(Action.DistrictWisePanchayat(data)),
    getAllDistrictwiseAssignedTrainer1: (data) => dispatch(Action.getAllDistrictwiseAssignedTrainer1(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RejectedTrainer);