import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  TablePagination,
  Paper,
} from "@mui/material";
// import * as Action from "../../actions/adminAction";
// import Stack from "@mui/material/Stack";
// import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
// import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
// import { Edit, Visibility, Delete } from "@mui/icons-material";
import React, { useState } from "react";

export const ViewAssignDistrict2 = ({ userDetails }) => {
  // console.log("UserDetails::::", userDetails);
  // Getting district and _id 
  const arr = userDetails?.assignDistrictName?.map((item, index) => {
    return {
      'district': item,
      'district_id': userDetails.assignDistrict_id[index],
    }
  })

  const classes = useStyles();
  const navigate = useNavigate();
  //data variables
  // const [city, setCity] = useState("");
  // const [taluka, setTaluka] = useState("");
  // const [panchayat, setPanchayat] = useState("");
  // const [trainerData, setTrainerData] = useState([]);
  const [Data, setData] = useState(arr);
  const [loading, setLoading] = useState(false);



  //Table Pagination variables
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //Table Pagination Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <div className={classes.main}>
      <center>
        <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }}>
          View Assigned District
        </Typography>
      </center>

      <Paper sx={{ overflow: "hidden", width: "90%" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>SR No.</strong>
                </TableCell>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>District</strong>
                </TableCell>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>Status</strong>
                </TableCell>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>Action</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <div className="spinner-border text-primary" role="status" />
            ) : (
              <TableBody>
                {Data.length > 0 ? (
                  Data.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.district}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          Assigned
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <Button
                            variant="contained"
                            onClick={() =>
                              navigate(
                                "/district-coordinator/district-details",
                                { state: row }
                              )
                            }
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>NO Data Found</TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          // count={count ? count : 0}
          count={Data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 30,
  },
  card: {
    width: "1200px",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  passmodal: {
    width: "350px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  profilemodal: {
    width: "1400px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  middlePosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    width: "70%",
    background: "white",
    marginTop: "20px",
    padding: "5px 15px",
  },
}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewAssignDistrict2);
