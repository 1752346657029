import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, TableRow, TableCell, Typography, Button, Paper, Modal, IconButton, TextField, Card } from "@mui/material";
import * as Action from "../../actions/adminAction"
// import Image1 from "../../Assets/Image/satara_Mandave.JPG"
// import Image2 from "../../Assets/Image/satara_Mandave_2.jpg"
import { useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
// import moment from "moment";
require('../../App.css')



const activities = [
  'Progress',
  'Snacks',
  'Group Photo',
  'Study Material',
  'Notepad and Pen',
  'Attendance Sheet',
  'Feedback Form',
  'Training Outcome',
  'Training Agenda',
  'Study Material Proof',
  'Distribution of Ebook',
  'Beneficiary Book'
]

export const ViewTrainerDocs = ({ userDetails, getCategories, ApproveEvidence, RejectEvidence }) => {

  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const Trainer = location.state

  const [openModal, setOpenModal] = useState(false);
  const [rejectModal, setrejectModal] = useState(false)
  const [ApproveMsg, setApproveMsg] = useState(false)
  const [loader, setLoader] = useState(false)

  const [categories, setcategories] = useState([])
  const [SelectedCategory, setSelectedCategory] = useState('')
  const [evidenceId, setEvidenceId] = useState('')
  const [comment, setcomment] = useState('')

  // console.log("Data", Trainer)
  // console.log("Details", userDetails)
  // console.log("first",location.state)

  useEffect(() => {
    getAllCategories()
  }, [])

  useEffect(() => {
    // console.log("Props", location.state)
}, [])
  const getAllCategories = async () => {
    const result = await getCategories({
      "grampanchayat_id": Trainer?.grampanchayat_id,
      "trainingModule_id": Trainer?.trainingModule_id
    })
    if (result) {
      // console.log("Categories", result)
      setcategories(result)
    }
  }

  const ApproveImage = async (ID) => {
    let name = userDetails?.firstName + userDetails?.lastName
    const result = await ApproveEvidence({
      approveRejectUser_id: userDetails?._id,
      approveRejectUserName: name,
      updatedBy: userDetails?._id,
      trainerEvidence_id: ID
    })
    if (result) {
      // console.log("Image Aprroved", result)
      setApproveMsg(true)
    }
  }

  const openReject = (row) => {
    // console.log("Reject Now")
    setEvidenceId(row?._id)
    setrejectModal(true)
  }

  const RejectImage = async () => {
    let name = userDetails?.firstName + userDetails?.lastName
    const result = await RejectEvidence({
      approveRejectComment: comment,
      approveRejectUser_id: userDetails?._id,
      approveRejectUserName: name,
      updatedBy: userDetails?._id,
      trainerEvidence_id: evidenceId,
    })
    if (result) {
      // console.log("Reject Photo", result)
      setrejectModal(false)
    }
  }

  return (
    <div className={classes.main}>
      <Card className={classes.card} style={{ backgroundColor: 'whitesmoke' }}>
        <div style={{ backgroundColor: '#3A3A56', display: 'flex', flexDirection: 'row', width: '100%', margin: 0 }}>
          <IconButton style={{ margin: 10, padding: 0 }}>
            <Typography style={{ fontFamily: 'sans-serif', fontWeight: 700, color: 'white', fontSize: 24, backgroundColor: '#3A3A56' }} sx={{
            }} >Training Location Data</Typography>
          </IconButton>
        </div>
        <div>
          <Table>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Training Location Name: </TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.grampanchayatName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Sarpanch Name:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.sarpanchName ? Trainer?.sarpanchName : '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>GramSevakh Name:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.gramsevakhName ? Trainer?.gramsevakhName : '-' }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Description:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20}}>Taluka:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.talukaName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20}}>District:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.districtName}</TableCell>
            </TableRow>
           
          </Table>
          {/* <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }}>Categories</Typography>
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 50, flexWrap: 'wrap', padding: '0px 10%' }}>
            {categories?.length > 0 && categories !== 'No data found' ? categories?.map((item) => {
              return (
                <div className='statusCard' onClick={() => { setOpenModal(!openModal); setSelectedCategory(item) }}>
                  {item._id}
                </div>
              )
            }) : 'No Categories found'}
          </div> */}
        </div>
        <center>
          <Button variant="contained" style={{ margin: 20 }} onClick={() => navigate(-1)}>Back</Button>
        </center>
      </Card>

      <Modal
        className={classes.middlePosition}
        open={openModal}
        onClose={(e) => {
          e.preventDefault();
          setOpenModal(!openModal);
        }}
      >
        <Paper className={classes.profilemodal} style={{ marginTop: 70 }}>
          <Stack direction="row" justifyContent="space-between"
            alignItems="center" spacing={2}>
            <Stack direction="column">
              <Typography variant='h6' component='div'>Category Name - {SelectedCategory?._id}</Typography>
            </Stack>
            <IconButton aria-label="delete" onClick={e => {
              e.preventDefault();
              setOpenModal(!openModal);
            }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <h3>Images</h3>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', flexWrap: 'wrap' }}>
            {SelectedCategory?.category?.length > 0 ? SelectedCategory?.category?.map((row) => {
              if (row?.status === 'approve') {
                return (
                  <div className='imgContainer-approved'>
                    <div >
                      <button className='reject' onClick={() => openReject(row)}>
                        Reject
                      </button>
                    </div>
                    <div className='img'>
                      <a target="_blank" href={row?.documentUrl}><img src={row?.documentUrl} style={{ height: '100%', width: '100%' }} /></a>
                    </div>
                  </div>
                )
              }
              else if (row?.status === 'reject') {
                return (
                  <div className='imgContainer-rejected'>
                    <div >
                      <button className='approve' onClick={() => ApproveImage(row?._id)} >
                        Approve
                      </button>
                    </div>
                    <div >
                      <button className='reject' onClick={() => openReject(row)}>
                        Reject
                      </button>
                    </div>
                    <div className='img'>
                      <a target="_blank" href={row?.documentUrl}><img src={row?.documentUrl} style={{ height: '100%', width: '100%' }} /></a>
                    </div>
                  </div>
                )
              }
              else {
                return (
                  <div className='imgContainer'>
                    <div >
                      <button className='approve' onClick={() => ApproveImage(row?._id)} >
                        Approve
                      </button>
                    </div>
                    <div >
                      <button className='reject' onClick={() => openReject(row)}>
                        Reject
                      </button>
                    </div>
                    <div className='img'>
                      <a target="_blank" href={row?.documentUrl}><img src={row?.documentUrl} style={{ height: '100%', width: '100%' }} /></a>
                    </div>
                  </div>
                )
              }
            }) : 'No Images Available'}
          </div>
        </Paper>
      </Modal>

      <Modal
        className={classes.middlePosition}
        open={rejectModal}
        onClose={(e) => {
          e.preventDefault();
          setrejectModal(false)
        }}
      >
        <Paper className={classes.passmodal}>
          <Stack direction="row" justifyContent="space-between"
            alignItems="center" spacing={2}>
            <Stack direction="column">
              <Typography variant='h6' component='div'>Reject Comment</Typography>
            </Stack>
            <IconButton aria-label="delete" onClick={e => {
              e.preventDefault();
              setrejectModal(false);
            }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10, flexDirection: 'column' }}>
            <TextField
              label="Reject Comment"
              style={{ width: 300 }}
              value={comment}
              onChange={(e) => setcomment(e.target.value)}
            />
            <Button style={{ margin: 10 }} variant="contained" disabled={comment.length === 0} onClick={() => RejectImage()}>Submit</Button>
          </div>
        </Paper>
      </Modal>

      <Modal
        className={classes.middlePosition}
        open={ApproveMsg}
        onClose={(e) => {
          e.preventDefault()
          setApproveMsg(false)
        }}
      >
        <Paper className={classes.passmodal}>
          <Stack direction="row" justifyContent="space-between"
            alignItems="center" spacing={2}>
            <Stack direction="column">
              <Typography variant='h6' component='div'>Image Approved</Typography>
            </Stack>
            <IconButton aria-label="delete" onClick={e => {
              e.preventDefault();
              setApproveMsg(false);
            }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <div style={{ display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',marginTop:10 }}>
            <h3>Image Approved Successfully !!!</h3>
            <Button variant="contained" onClick={ () => setApproveMsg(false) }>Ok</Button>
          </div>
        </Paper>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column',
    marginTop: 30
  },
  card: {
    width: '100%',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  passmodal: {
    width: '350px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  profilemodal: {
    width: '1000px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  middlePosition: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  input: {
    width: '70%',
    background: 'white',
    marginTop: '20px',
    padding: '5px 15px'
  },
  image: {
    width: 150,
    height: 150
  },
  imageApprove: {
    backgroundColor: 'green',
    color: 'white'
  },
  imageReject: {
    backgroundColor: 'red',
    color: 'white'
  }
}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,

  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCategories: (data) => dispatch(Action.getCategories(data)),
    ApproveEvidence: (data) => dispatch(Action.ApproveEvidence(data)),
    RejectEvidence: (data) => dispatch(Action.RejectEvidence(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewTrainerDocs)