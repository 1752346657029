import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Button, TablePagination, Paper,CircularProgress } from "@mui/material";
// import * as Action from "../../actions/adminAction"
// import Stack from "@mui/material/Stack";
// import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
// import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
// import { Edit, Visibility, Delete } from "@mui/icons-material";
import React, { useState, useEffect } from 'react';

// import {  useLocation } from "react-router-dom";



export const GrampanchayatTrainerList2 = ({ userDetails }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation();

  //Table Pagination variables
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  //API Response
  const [Data, setData] = useState([])
  const [loading, setLoading] = useState(false);
  // console.log("user....details...", userDetails)
  // console.log("Props", location.state);

  //Modal Variables
  const [assign, setAssign] = useState(false)

  //Table Pagination Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    DistrictwiseGrampanchyatList();
  }, []);

  const DistrictwiseGrampanchyatList = async () => {
    setLoading(true)

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let data = {
      distric_id: location.state.district_id,

    };

    let requestoptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    await fetch(process.env.REACT_APP_BASE_URL+"/location/gettrainingLocation/list/district",
      requestoptions
    )
      .then((response) => response.json())
      .then((res) => {
        setLoading(false)
        // console.log("-------View response districtwise Grampanchyatlist----------", res);
        let a = res!==undefined && res.length>0 ? res : undefined;
        setData(a);
      })
      .catch((error) => {
        setLoading(false)
        console.error("Error:", error);
      });

    //   }
    //   else {
    //     // console.log("Form is Invalid")
    //   }
  };







  return (
    <div>
      <div className={classes.main} >
        <center>
          <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }} >Assigned Training Location</Typography>
        </center>
        { loading ? <CircularProgress color='primary' /> :   <Paper sx={{ overflow: 'hidden' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ overflow: 'hidden', width: '90%' }}>
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Sr. No.</strong></TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Training Location</strong></TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>District Name</strong></TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>gramsevak Name</strong></TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Sarpanch Name</strong></TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Taluka</strong></TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Action</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  Data !== 'No data found' && Data.length > 0 ? Data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                    return (
                      <TableRow key={item?._id}>
                        <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{index + 1}</TableCell>
                        <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{item?.locationName}</TableCell>
                        <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{item?.districtName}</TableCell>
                        <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{item?.gramsevakhName !== 'null' && item?.gramsevakhName !== null ? item?.gramsevakhName : '-'}</TableCell>
                        <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{item?.sarpanchName !== 'null' && item?.gramsevakhName !== null ? item?.gramsevakhName : '-'}</TableCell>
                        <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{item?.talukaName}</TableCell>
                        {/* <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{item?.gramsevakhName}</TableCell> */}
                        <TableCell style={{ textAlign: 'center' }}>
                          <Button variant='contained' onClick={() => navigate('/district-coordinator/view', { state: item })} style={{ margin: 5 }} >View</Button>
                          {/* <Button variant='contained' onClick={() => navigate('/trainerLayout/uploadDocument', { state: row })} style={{ margin: 5 }} >Upload Document</Button> */}
                        </TableCell>

                      </TableRow>
                    )
                  }) : <TableRow>NO Data Found</TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            // count={count ? count : 0}
            count={Data?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper> }
        <center>
          <Button variant="contained" style={{ margin: 20 }} onClick={() => navigate(-1)}>Back</Button>
        </center>
      </div>
    </div>
  )
}


const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column',
    marginTop: 30
  },
  card: {
    width: '1200px',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  passmodal: {
    width: '350px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  profilemodal: {
    width: '1400px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  middlePosition: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  input: {
    width: '70%',
    background: 'white',
    marginTop: '20px',
    padding: '5px 15px'
  },
}));

// const mapStateToProps = (state) => ({})

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(GrampanchayatTrainerList2)



