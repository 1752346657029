import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Button, TablePagination, Paper, TextField, FormControl } from "@mui/material";
import { CircularProgress } from '@mui/material';
// import * as Action from "../../actions/adminAction"
// import Stack from "@mui/material/Stack";
// import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
// import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
// import { Edit, Visibility, Delete } from "@mui/icons-material";
import React, { useState } from 'react';
import { BASE_URL } from '../../utils/urls'
import dayjs from "dayjs";
// import { LoadScriptNext } from '@react-google-maps/api';
// import { format } from 'date-fns';

const TrainerData = [
  {
    Gram_Panchayat: 'ADEGAON',
    Assignment_Date: '12/12/2022',
    Batch: 'Morning',
    Module_Desc: 'xyz',
    Trainer_Name: 'abc',
    Trainer_Mobile: 8232988832,
  },
  {
    Gram_Panchayat: 'BAJARWADA',
    Assignment_Date: '12/12/2022',
    Batch: 'Morning',
    Module_Desc: 'xyz',
    Trainer_Name: 'abc',
    Trainer_Mobile: 8232988832,
  },
  {
    Gram_Panchayat: 'ADEGAON',
    Assignment_Date: '12/12/2022',
    Batch: 'Morning',
    Module_Desc: 'xyz',
    Trainer_Name: 'abc',
    Trainer_Mobile: 8232988832,
  },
  {
    Gram_Panchayat: 'BAJARWADA',
    Assignment_Date: '12/12/2022',
    Batch: 'Morning',
    Module_Desc: 'xyz',
    Trainer_Name: 'abc',
    Trainer_Mobile: 8232988832,
  },
  {
    Gram_Panchayat: 'DHANORA',
    Assignment_Date: '12/12/2022',
    Batch: 'Morning',
    Module_Desc: 'xyz',
    Trainer_Name: 'abc',
    Trainer_Mobile: 8232988832,
  },
  {
    Gram_Panchayat: 'ADEGAON',
    Assignment_Date: '12/12/2022',
    Batch: 'Morning',
    Module_Desc: 'xyz',
    Trainer_Name: 'abc',
    Trainer_Mobile: 8232988832,
  },
  {
    Gram_Panchayat: 'CHINCHALA',
    Assignment_Date: '12/12/2022',
    Batch: 'Morning',
    Module_Desc: 'xyz',
    Trainer_Name: 'abc',
    Trainer_Mobile: 8232988832,
  },

]

export const RollOutPlanDC = ({ userDetails }) => {
  // // console.log("Rollout UserID::::",userDetails._id)
  const classes = useStyles()
  const navigate = useNavigate()

  const [trainerData, setTrainerData] = useState([])
  const [filteredData, setFilteredData] = useState()
  const [filterString, setFilterString] = useState()
  const [loader, setLoader] = useState(false)

  //Table Pagination variables
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //Modal Variables
  const [assign, setAssign] = useState(false)

  React.useEffect(() => {

    fetchRolloutPlan();

  }, [])

  const fetchRolloutPlan = () => {
    setLoader(true);
    const payLoad = {
      "distric_id": userDetails?.assignDistrict_id,
    }
    const opt = {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payLoad),
    }
    // console.log("PAYLOAD:::-", payLoad)
    fetch(BASE_URL + "/trainingassigntrainer/districwiselist", opt)
      .then(res => {
        return res.json()
      })
      .then(data => {
        // console.log("Rollout plan", data);
        // console.log("TODAY DATE____-----******^^^^^", dayjs(new Date()).format("DD-MM-YYYY"))
        setTrainerData(data)
        setFilteredData(data)
        setLoader(false);
      })
      .catch(err => {
        // console.log("Error", err);
        setLoader(false);
      })
  }

  const filterData = (char) => {
    setLoader(true);
    // console.log(char)
    // console.log("Charracter Array", filteredData)
    let f = filteredData?.filter(item=>item?.trainerNameAll[0]?.toLowerCase()?.startsWith(char?.toLowerCase())                                ||
                                       item?.trainerNameAll[1]?.toLowerCase()?.startsWith(char?.toLowerCase())                                ||
                                       item?.trainerMobileAll[0]?.toString()?.startsWith(char?.toLowerCase())                                 ||
                                       item?.trainerMobileAll[1]?.toString()?.startsWith(char?.toLowerCase())                                 ||
                                       item?.districtName?.toString().toLowerCase()?.startsWith(char?.toLowerCase())                          ||
                                       item?.grampanchayatName?.toString().toLowerCase()?.startsWith(char?.toLowerCase())                     ||
                                       dayjs(item?.startDate).format('DD-MM-YYYY').toString().toLowerCase()?.startsWith(char?.toLowerCase())  ||
                                       item?.batch?.toString().toLowerCase()?.startsWith(char?.toLowerCase())                                 ||
                                       item?.trainingModuleName?.toString().toLowerCase()?.startsWith(char?.toLowerCase()))
    setTrainerData(f)
    // console.log("MyData",f)
    setLoader(false);
  }

  //Table Pagination Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <div className={classes.main} >
      <div className={classes.main}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%' }}>
          <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }} >Rollout Plan</Typography>
          {/* <Button variant='contained' onClick={() => { }} style={{ margin: 5 }} >View</Button> */}
        </div>
        <FormControl sx={{ m: 1, marginRight: 3 }}>
          <TextField
            style={{ width: 300 }}
            // error={TrainerName.match(/[0-9+@#$&%!~]/)}
            id="outlined-adornment-TrainerName"
            label="Filter"
            value={filterString}
            onChange={(e) => {setFilterString(e.target.value);filterData(e.target.value)}}
          // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
          />
        </FormControl>
      </div>
      {loader ? <CircularProgress color='primary' /> : <Paper sx={{ overflow: 'hidden', width: '90%' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Sr. No.</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>District</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Training Location</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Training Date</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Trainer</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Mobile</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Support Trainer</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Mobile</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Batch</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Module</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Action</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                trainerData.length > 0 && trainerData !== 'No data found' ? trainerData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{index + 1}</TableCell>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.districtName}</TableCell>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.grampanchayatName}</TableCell>
                      {/* <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.startDate?.split('T')[0].split('-')[2] + "-" + row?.startDate?.split('T')[0].split('-')[1] + "-" + row?.startDate?.split('T')[0].split('-')[0]}</TableCell> */}
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.startDate? dayjs(row?.startDate).format('DD-MM-YYYY'):'-'}</TableCell>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.trainerNameAll[0] ? row?.trainerNameAll[0] : '-'}</TableCell>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.trainerMobileAll[0] ? row?.trainerMobileAll[0] : '-'}</TableCell>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.trainerNameAll[1] ? row?.trainerNameAll[1] : '-'}</TableCell>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.trainerMobileAll[1] ? row?.trainerMobileAll[1] : '-'}</TableCell>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.batch ? row?.batch?.toUpperCase() : '-'}</TableCell>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.trainingModuleName}</TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <Button variant='contained' onClick={() => navigate('/district-coordinator/rollout-plan-details', { state: row })} style={{ margin: 5 }} >View</Button>
                        {/* <Button variant='contained' onClick={() => navigate('/trainerLayout/uploadDocument', { state: row })} style={{ margin: 5 }} >Upload Document</Button> */}
                      </TableCell>
                    </TableRow>
                  )
                })
                  : <TableRow>NO Data Found</TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          // count={count ? count : 0}
          count={trainerData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>}

    </div>
  )
}


const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column',
    marginTop: 30
  },
  card: {
    width: '1200px',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  passmodal: {
    width: '350px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  profilemodal: {
    width: '1400px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  middlePosition: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  input: {
    width: '70%',
    background: 'white',
    marginTop: '20px',
    padding: '5px 15px'
  },
}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
  }
};

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(RollOutPlanDC);