import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Paper,
  CircularProgress,
} from "@mui/material";
import * as Action from "../../actions/adminAction";
// import Stack from "@mui/material/Stack";
// import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
// import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
// import { Edit, Visibility, Delete } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
// import moment from "moment";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export const NewDistrictList = ({ userDetails,afda }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  //Table Pagination variables
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [districtData, setDistrictData] = useState([]);
  // console.log(userDetails)

  useEffect( () => {
    getData()
  },[] )

  const getData = async () => {
    const arr = await afda();
    setDistrictData(arr)
    // console.log(arr)
  }

  //Table Pagination Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // console.log("Client District Data==>",districtData);
  return (
    <div className={classes.main}>
      <h1>District List</h1>
      <div className={classes.table}>
        {loader ? (
          <center>
            <CircularProgress color="primary" />
          </center>
        ) : (
          <Paper sx={{ overflow: "hidden", width: "100%" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                      <strong>SR No.</strong>
                    </TableCell>
                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                      <strong>District Name</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {districtData !== undefined && districtData.length > 0 ? (
                    districtData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow>
                             <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                             {index+1}
                             </TableCell>
                          <TableCell style={{ fontSize: 16, textAlign: "center"}}>
                            {row.districtName}
                           </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>NO Data Found</TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              // count={count ? count : 0}
              count={districtData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 30,
  },
  table: {
    margin: 20,
  },
}));

const mapStateToProps = (state) => {
    return {
      userDetails: state.auth.userDetails,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        afda: () => dispatch(Action.getAllDistrictData()),
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(NewDistrictList);
