import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Table,
  TableRow,
  TableCell,
  Typography,
  Button,
  Paper,
  Modal,
  IconButton,
  Card,
  CircularProgress,
} from "@mui/material";
import * as Action from "../../actions/adminAction";
// import Image1 from "../../Assets/Image/satara_Mandave.JPG";
// import Image2 from "../../Assets/Image/satara_Mandave_2.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
// import Videos from "../../Assets/Image/Video.png";
// import DOC from "../../Assets/Image/doc.png";
require("../../App.css");

export const CreateReport = ({
  userDetails,
  getCategories,
  getSingleTraining,
  GenerateFeedbackReport,
  GenerateSummaryReport,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const Trainer = location.state;

  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [categories, setcategories] = useState([]);
  const [Training, setTraining] = useState("");
  const [reportDownload, setReportDownload] = useState(false);
  const [feedbackLoader, setFeedbackLoader] = useState(false);
  const [summaryLoader, setsummaryLoader] = useState(false);

  //Feedback Form

  // console.log("Data", Trainer)
  // console.log("Details", userDetails)

  const getAllCategories = async () => {
    setLoader(true);
    const result = await getCategories({
      grampanchayat_id: Trainer?.grampanchayat_id,
      trainingModule_id: Trainer?.trainingModule_id,
    });
    if (result) {
      // console.log("Categories", result)
      setcategories(result);
      setLoader(false);
    }
  };

  const getTrainingData = async () => {
    setLoader(true);
    const result = await getSingleTraining({
      trainingAssignTrainer_id: Trainer?._id,
    });
    if (result !== "No data found" && result !== undefined) {
      // console.log("Training", result[0])
      setTraining(result[0]);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    getAllCategories();
    getTrainingData();
  }, []);

  const downloadTrainingReport = (Link, item) => {
    setReportDownload(true);
    fetch(Link).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download =
          item?.districtName +
          "-" +
          item?.grampanchayatName +
          "-" +
          item?.trainingModuleName +
          "-" +
          "TrainingReportPDF.pdf";
        alink.click();
        setReportDownload(false);
      });
    });
  };

  const generateFeedback = async () => {
    // console.log("Generating Feedback")
    setFeedbackLoader(true);
    const result = await GenerateFeedbackReport({
      trainingAssign_id: Training?._id,
      trainingModule_id: Training?.trainingModule_id,
      grampanchayat_id: Training?.grampanchayat_id,
      taluka_id: Training?.taluka_id,
      distric_id: Training?.distric_id,
    });
    if (result.message != "No report data found") {
      downloadFeedbackReport(result, Training);
    } else {
      alert(result.message);
      setFeedbackLoader(false);
    }
  };

  const downloadFeedbackReport = (val, item) => {
    setFeedbackLoader(true);
    fetch(val).then((response) => {
      // console.log(val)
      return response.blob().then((blob) => {
        // Creating new object of PDF file
        // console.log(blob)
        const ff = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = ff;
        alink.download =
          item?.districtName +
          "-" +
          item?.grampanchayatName +
          "-" +
          item?.trainingModuleName +
          "-" +
          "FeedbackReportPDF.pdf";
        alink.click();
        setFeedbackLoader(false);
      });
    });
  };

  const generateSummary = async () => {
    // console.log("Generating Summary")
    setsummaryLoader(true);
    const result = await GenerateSummaryReport({
      trainingAssign_id: Training?._id,
      trainingModule_id: Training?.trainingModule_id,
      grampanchayat_id: Training?.grampanchayat_id,
      taluka_id: Training?.taluka_id,
      distric_id: Training?.distric_id,
    });

    if (result.message != "No report data found") {
      downloadSummaryReport(result, Training);
    } else {
      alert(result.message);
      setsummaryLoader(false);
    }
  };

  const downloadSummaryReport = (lin, item) => {
    setsummaryLoader(true);
    fetch(lin).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download =
          item?.districtName +
          "-" +
          item?.grampanchayatName +
          "-" +
          item?.trainingModuleName +
          "-" +
          "SummaryReportPDF.pdf";
        alink.click();
        setsummaryLoader(false);
      });
    });
  };

  return (
    <div className={classes.main}>
      {loader ? (
        <CircularProgress color="primary" />
      ) : Training !== undefined && Training !== "No data found" ? (
        <Card
          className={classes.card}
          style={{ backgroundColor: "whitesmoke" }}
        >
          <div
            style={{
              backgroundColor: "#3A3A56",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              margin: 0,
            }}
          >
            <IconButton style={{ margin: 10, padding: 0 }}>
              <Typography
                style={{
                  fontFamily: "sans-serif",
                  fontWeight: 700,
                  color: "white",
                  fontSize: 24,
                  backgroundColor: "#3A3A56",
                }}
                sx={{}}
              >
                Trainer Data
              </Typography>
            </IconButton>
          </div>
          <div>
            <Table>
              <TableRow>
                <TableCell style={{ fontSize: 20 }}>Trainer:</TableCell>
                <TableCell style={{ fontSize: 20 }}>
                  {Training?.trainerNameAll ? Training?.trainerNameAll[0] : "-"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontSize: 20 }}>District:</TableCell>
                <TableCell style={{ fontSize: 20 }}>
                  {Training?.districtName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontSize: 20 }}>Training Location :</TableCell>
                <TableCell style={{ fontSize: 20 }}>
                  {Training?.grampanchayatName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontSize: 20 }}>
                  Training Module:
                </TableCell>
                <TableCell style={{ fontSize: 20 }}>
                  {Training?.trainingModuleName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontSize: 20 }}>
                  Support Trainer:
                </TableCell>
                <TableCell style={{ fontSize: 20 }}>
                  {Training?.trainerNameAll ? Training?.trainerNameAll[1] : "-"}
                </TableCell>
              </TableRow>
              {Training?.trainerMobileAll &&
              Training?.trainerMobileAll.length > 0
                ? Training?.trainerMobileAll.map((row, index) => {
                    return (
                      <TableRow>
                        <TableCell style={{ fontSize: 20 }}>
                          Trainier {index + 1} Mobile:
                        </TableCell>
                        <TableCell style={{ fontSize: 20 }}>
                          {row !== null ? row : "-"}
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
              <TableRow>
                <TableCell style={{ fontSize: 20 }}>Training Date:</TableCell>
                <TableCell style={{ fontSize: 20 }}>
                  {moment(Training?.startDate).format("DD MMM YYYY")}
                </TableCell>
              </TableRow>
            </Table>
          </div>

          <center>
            <Button
              variant="contained"
              style={{ margin: 10 }}
              onClick={() =>
                navigate("/admin/Report-form", { state: Training })
              }
            >
              Genrate All Report
            </Button>
          </center>
          <center>
            {reportDownload ? (
              <CircularProgress color="primary" />
            ) : (
              <Button
                variant="contained"
                style={{ margin: 20 }}
                disabled={!Training?.finalReportUrl}
                onClick={() =>
                  downloadTrainingReport(Training?.finalReportUrl, Training)
                }
              >
                Download entire report
              </Button>
            )}
            {feedbackLoader ? (
              <CircularProgress color="primary" />
            ) : (
              <Button
                variant="contained"
                style={{ margin: 10 }}
                disabled={!Training?.finalReportUrl}
                onClick={() => {
                  generateFeedback();
                }}
              >
                Download Feedback Report
              </Button>
            )}
            {summaryLoader ? (
              <CircularProgress color="primary" />
            ) : (
              <Button
                variant="contained"
                style={{ margin: 10 }}
                disabled={!Training?.finalReportUrl}
                onClick={() => {
                  generateSummary();
                }}
              >
                Download Summary Report
              </Button>
            )}
          </center>
          <center>
            <Button
              variant="contained"
              style={{ margin: 20 }}
              onClick={() => navigate("/admin/generate-training-reportList")}
            >
              Back
            </Button>
          </center>
        </Card>
      ) : (
        <h2>No data found</h2>
      )}

      <Modal
        className={classes.middlePosition}
        open={openModal}
        onClose={(e) => {
          e.preventDefault();
          setOpenModal(!openModal);
        }}
      >
        <Paper className={classes.profilemodal} style={{ marginTop: 70 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="column">
              <Typography variant="h6" component="div">
                Activity
              </Typography>
            </Stack>
            <IconButton
              aria-label="delete"
              onClick={(e) => {
                e.preventDefault();
                setOpenModal(!openModal);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <div></div>
        </Paper>
      </Modal>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 30,
  },
  card: {
    width: "70%",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  passmodal: {
    width: "350px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  profilemodal: {
    width: "870px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  middlePosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    width: "70%",
    background: "white",
    marginTop: "20px",
    padding: "5px 15px",
  },
  image: {
    width: 150,
    height: 150,
  },
  imageApprove: {
    backgroundColor: "green",
    color: "white",
  },
  imageReject: {
    backgroundColor: "red",
    color: "white",
  },
}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategories: (data) => dispatch(Action.getCategories(data)),
    getSingleTraining: (data) => dispatch(Action.getSingleTraining(data)),
    GenerateFeedbackReport: (data) =>
      dispatch(Action.GenerateFeedbackReport(data)),
    GenerateSummaryReport: (data) =>
      dispatch(Action.GenerateSummaryReport(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateReport);
