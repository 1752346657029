import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Button, TablePagination, Box, Paper, CardContent, Modal, IconButton, TextField, FormControl, Select, InputLabel, MenuItem, Alert } from "@mui/material";
import * as Action from "../../actions/adminAction"
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from "react-router-dom";
import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
import { Edit, Visibility, Delete } from "@mui/icons-material";
import React, { useEffect, useState } from 'react';
// import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Compressor from 'compressorjs';
import S3 from "react-aws-s3";
import CircularProgress from '@mui/material/CircularProgress';
import img from "../../Assets/Image/gsda_logo.jpeg"
import ModeIcon from '@mui/icons-material/Mode';
import axios from 'axios';
import img1 from '../../Assets/Image/nope-not-here.webp'



export const TraningModule = ({ }) => {

  // hooks and Props
  const classes = useStyles()

  //data variables
  const [TrainingModuleName, setTrainingModuleName] = useState('')
  const [description, setDescription] = useState()
  const [image, setImage] = useState('')
  const [TrainerData, setTrainerData] = useState([])
  const [imgUploading, setImgUploading] = useState(false);
  const [imgArray, setImgArray] = useState();
  const [imgUploadingError, setImgUploadingError] = useState(false);
  const [loader, setLoader] = useState(false)
  const [dataLoad,setDataLoad] = useState(false)
  const [trainingModule_id,setId] = useState()
  const [deleteTrainingModule,setDeleteTrainingModule]=useState()




  //Table Pagination variables
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //Table Pagination Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //Modal variables
  const [openTrainingModule, setOpenTrainingModule] = useState(false)
  const [EditTrainingModule, setEditTrainingModule] = useState(false)

  //Error variables
  const [NameError, setNameError] = useState(false)
  const [DescriptionError, setDescriptionError] = useState(false)
  const [imageError, setImageError] = useState(false)
  const [Error, setError] = useState(false)

  useEffect(() => {
    getTrainerData()
  }, [])
   

   const getTrainerData = async()=>{
    setDataLoad(true)
    await axios.get(process.env.REACT_APP_BASE_URL+"/trainingmodule/gettrainingmodulelist")
      .then((res) => {
        // console.log("hello.........", res.data);
        // console.log("module res",TrainerData);
        if(res.data !== 'No data found'){
        setTrainerData(res.data)
        }
        setDataLoad(false)
      })
      .catch((err) => {
        // console.log("error ", err);
        setDataLoad(false)
      })
   }

  const handleChange = async (e) => {
    if (e.target.files.length) {
      const img = URL.createObjectURL(e.target.files[0]);
      if(e.target.files[0].type?.includes("image")){
        setLoader(true)
        uploadImageToS3(e.target.files[0]);
      }else{
        alert("Upload Image")
      }
    }
  }
  const openModule = () => {
    setTrainingModuleName("")
    setDescription("")
    setImgArray("")
    setOpenTrainingModule(true)


  }


  const uploadImageToS3 = (event) => {
    setImgUploading(true);
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: process.env.REACT_APP_DIR_NAME + "/" + TrainingModuleName,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: "https://gsda-images2.s3.ap-south-1.amazonaws.com"
    };

    let fileInput = false;
    const image = event;
    // console.log("image data before compression-------------- ", image);
    if (event) {
      fileInput = true;
    }
    if (fileInput) {
      new Compressor(image, {
        quality: 0.9,
        success: (compressedResult) => {
          let getTimeStamp = new Date().getTime();
          let newFileName = getTimeStamp + "_";
          // console.log("------- filename image upload --------", newFileName);

          const ReactS3Client = new S3(config);

          ReactS3Client.uploadFile(compressedResult, newFileName)
            .then((res) => {
              // setImage(res.location);

              if (res.status === 204) {
                setImgUploading(false);
                // console.log("uploded image", res);
                // console.log("image uploaded successfully!!!!");
                setImgArray(res.location);
                setLoader(false)
              }
              else {
                setImgUploadingError(true);
                setLoader(false)
              }
            }).catch(err => {

              console.error(err);
            });
        },
      });
      // console.log("--------------- Upload image -----------");
    }
  };



  const foramvaliadate = () => {
    TrainingModuleName.trim()
    description.trim()
    let IsformValid = true;


    if (!TrainingModuleName) {
      IsformValid = false;
      setNameError(true)
    } else if (TrainingModuleName.match(/[1-9+@#$%!~]/)) {
      // console.log("elsePart")
      IsformValid = false;
      setError(true)
    } else if(TrainingModuleName.replace(/\s/g,"").length <= 0 ){
            IsformValid = false;
            setNameError(true)


    }

    if (!description) {
      IsformValid = false;
      setDescriptionError(true)
      setError(true)
    } else if(description.replace(/\s/g,"").length<=0){
      setDescriptionError(true)
      IsformValid(false)
      
    }


    return IsformValid;
  }

  const handleAdd = async () => {
    setNameError(false)
    setDescriptionError(false)
    setImageError(false)
    

    if (foramvaliadate()  && Error === false) {
      const trainingModuleName = TrainingModuleName;
      const imgURL = imgArray;
     
      await axios.post(process.env.REACT_APP_BASE_URL+"/trainingmodule/inserttrainingmodule",{trainingModuleName,description,imgURL})
      .then((res)=>{
        // console.log("response from handle Add ",res);
        setOpenTrainingModule(false)
        getTrainerData()
      })
      .catch((err)=>{
        // console.log("err from handle ada",err)
      })

      setOpenTrainingModule(false)
    } else {
      // console.log("Form Invalid")
    }
  }

  const openEdit = (row) => {
    setTrainingModuleName(row?.trainingModuleName)
    setDescription(row?.description)
    setId(row?._id)

    setEditTrainingModule(true)
    

  }
  const openDelete = (row)=>{
    setId(row?._id)
    setDeleteTrainingModule(true)
  }
  const handleDelete = async()=>{
    
    await axios.put(process.env.REACT_APP_BASE_URL+"/trainingmodule/trainingmoduledelete",{trainingModule_id})
      .then((res)=>{

        // console.log("response from editHandle",res);
        setEditTrainingModule(false)
        getTrainerData()
      })
      .catch((err)=>{
        // console.log("error from handle Edit",err);
      })



  }

  
  const handleEdit = async (e) => {
    setNameError(false)
    setDescriptionError(false)
    // setImageError(false)
  


    if (foramvaliadate() && Error === false) {
      const trainingModuleName = TrainingModuleName;

      
      // console.log("Form Valid")
      await axios.put(process.env.REACT_APP_BASE_URL+"/trainingmodule/trainingmoduleupdate",{trainingModule_id,trainingModuleName,description})
      .then((res)=>{

        // console.log("response from editHandle",res);
        setEditTrainingModule(false)
        getTrainerData()
      })
      .catch((err)=>{
        // console.log("error from handle Edit",err);
      })
    } else {
      // console.log("Form Invalid")
    }
  }

  return (
    <div className={classes.main}>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Box sx={{ flex: 1 }}>
          <h1>Training Module</h1>
        </Box>
        <Box >
          <div style={{ marginTop: 30, right: 0 }}>
            <Button variant="contained" onClick={() => openModule()} ><PersonAddRounded style={{ marginRight: 10 }} />Add Training Module</Button>
          </div>
        </Box>
      </div>
      { dataLoad ? <CircularProgress color='primary' /> :      
       <Paper sx={{ overflow: 'hidden', width: '90%',marginTop:2 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>SR No.</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Training Module</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Description</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Image</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Action</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                TrainerData.length > 0 ? TrainerData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{index + 1}</TableCell>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.trainingModuleName}</TableCell>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.description}</TableCell>
                      {/* <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.imgURL}</TableCell> */}
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row.imgURL && row.imgURL !== "abc.com" ?<img src={row.imgURL} style={{ width: "80px", height: "80px" }}/>:<img src={img1} style={{ width: "80px", height: "80px" }}/>}</TableCell>

                      <TableCell style={{ fontSize: 16, textAlign: 'center'}}>
                        
                        <ModeIcon variant='contained' style={{color:"green"}}   onClick={() => openEdit(row)} />
                        <Delete variant='contained'style={{color:"red"}}  onClick={()=>openDelete(row)} />
                        
                      </TableCell>
                    </TableRow>
                  )
                })
                  : <TableRow>NO Data Found</TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          // count={count ? count : 0}
          count={ TrainerData !== "No data found" && TrainerData?.length > 0 ? TrainerData?.length : 0 }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper> }

      {/* Add Training Module */}
      <Modal
        className={classes.middlePosition}
        open={openTrainingModule}
        onClose={(e) => {
          e.preventDeafault();
          setOpenTrainingModule(false)

        }}
      >
        <Paper className={classes.profilemodal} >
          <Stack direction="row" justifyContent="space-between"
            alignItems="center" spacing={2}>
            <Stack direction="column">
              <Typography variant='h6' component='div'>Add Traning Module</Typography>
            </Stack>
            <IconButton aria-label="delete" onClick={e => {
              e.preventDefault();
              setOpenTrainingModule(false)
              setImgArray(null)

            }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >
            <CardContent style={{ display: 'flex', width: '90%', justifyContent: 'center', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row', }}>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 300 }}
                    error={TrainingModuleName.trim().match(/[0-9+@#$%!~]/)}
                    trainingModule_id="outlined-adornment-TrainerName"
                    label="Training Module Name"
                    value={TrainingModuleName}
                    onChange={(e) => setTrainingModuleName(e.target.value)}
                    helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Traning Module Name</span> : null}
                  />
                </FormControl>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 300 }}
                    // error={description.match(/[A-Za-z+@#$&%!~]/) || description.length !== 25 && description.length !== 0}
                    trainingModule_id="outlined-adornment-TrainerName"
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    helperText={DescriptionError ? <span style={{ color: 'red', fontSize: 15 }}>* Please Enter Description</span> : null}
                  />
                </FormControl>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography>Add Module Image: </Typography>
                    <Button variant="contained" component="label">
                      Upload
                      <input accept="image/*"
                       className={classes.input} trainingModule_id="icon-button-file" type="file"
                        onChange={(e) => handleChange(e)}
                        style={{ display: "none" }} />
                    </Button>
                    {imageError ? <span style={{ color: 'red', fontSize: 15 }}>* Please Upload Image</span> : null} 
                    {imgArray ? <img src={imgArray} style={{ width: "80px", height: "80px" }} /> : null}
                    {loader ? <CircularProgress /> : null}
                  </Stack>
                </FormControl>
              </div>
            </CardContent>
            {Error ? <Alert
              severity='warning'
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setError(false)
                  }}
                >
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
            >
              Please fill requested form properly
            </Alert> : null}
            <Button variant="contained"
              style={{ margin: 10 }}
              endIcon={<SendIcon />}
              onClick={(e) => {
                e.preventDefault();
                handleAdd()
              }} >
              ADD Training Module
            </Button>
          </div>
        </Paper>
      </Modal>

      {/* Update Trainer Module */}
      <Modal
        className={classes.middlePosition}
        open={EditTrainingModule}
        onClose={(e) => {
          e.preventDeafault();
          setEditTrainingModule(false)
        }}
      >
        <Paper className={classes.profilemodal} >
          <Stack direction="row" justifyContent="space-between"
            alignItems="center" spacing={2}>
            <Stack direction="column">
              <Typography variant='h6' component='div'>Update Training Module</Typography>
            </Stack>
            <IconButton aria-label="delete" onClick={e => {
              e.preventDefault();
              setEditTrainingModule(false)
              setImgArray(null)

            }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >
            <CardContent style={{ display: 'flex', width: '90%', justifyContent: 'center', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 300 }}
                    // error={TrainingModuleName.match(/[0-9+@#$&%!~]/)}
                    trainingModule_id="outlined-adornment-TrainerName"
                    label="Training Module Name"
                    value={TrainingModuleName}
                    onChange={(e) => setTrainingModuleName(e.target.value)}
                    helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Training Module Name</span> : null}
                  />
                </FormControl>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 300 }}
                    // error={TrainerMobile.match(/[A-Za-z+@#$&%!~]/) || TrainerMobile.length !== 10 && TrainerMobile.length !== 0}
                    trainingModule_id="outlined-adornment-TrainerName"
                    label="Description "
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    helperText={DescriptionError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Description</span> : null}
                  />
                </FormControl>
              </div>
              
            </CardContent>
            {Error ? <Alert
              severity='warning'
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setError(false)
                  }}
                >
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
            >
              Please fill requested form properly
            </Alert> : null}
            <Button variant="contained"
              style={{ margin: 10 }}
              endIcon={<SendIcon />}
              onClick={(e) => {
                e.preventDefault();
                handleEdit()
              }} >
              Update Training Module
            </Button>
          </div>
        </Paper>
      </Modal>
      {/* delete Training Module */}
      <Modal
        
        className={classes.middlePosition}
        open={deleteTrainingModule}
        onClose={(e) => {
          e.preventDeafault();
          setDeleteTrainingModule(false)
        }}
      >
        <Paper className={classes.profilemodal} >
          <Stack direction="row" justifyContent="space-between"
            alignItems="center" spacing={2}>
            
            <IconButton aria-label="delete" >
            </IconButton>
          </Stack>
          <Stack direction="row" style={{display:"flex",justifyContent:"center"}} >
              <Typography variant='h6'  style={{textAlign:"center",justifyContent:"center"}} component='div'>Are You Want To Delete Training Module</Typography>
            </Stack>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginTop:"24px" }} >
            
            
            <Button variant="contained"
              style={{ margin: 10 }}
              onClick={(e) => {
                e.preventDefault();
              
                setDeleteTrainingModule(false)
              }} >
                
              Cancle
            </Button>
            <Button variant="contained"
              style={{ margin: 10,backgroundColor:"red" }}
              endIcon={<Delete/>}
              onClick={(e) => {
                e.preventDefault();
                handleDelete()
                setDeleteTrainingModule(false)
              }} >
              Delete
            </Button>
          </div>
        </Paper>
      </Modal>
      
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column',
    marginTop: 30
  },
  card: {
    width: '1200px',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  passmodal: {
    width: '350px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  profilemodal: {
    width: '800px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '32px',
  },
  middlePosition: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  input: {
    width: '70%',
    background: 'white',
    marginTop: '20px',
    padding: '5px 15px',
  },
}));

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(TraningModule)