import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import HomeIcon from "@mui/icons-material/Home";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import PersonIcon from '@mui/icons-material/Person';
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import logo from "../Assets/Image/gsda_logo.jpg"
import LogoutIcon from '@mui/icons-material/Logout';
import * as Action from '../actions/adminAction';
import * as Auth from '../actions/authActions';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Profile from '../Assets/Image/Profile Admin.png'
import GroupIcon from '@mui/icons-material/Group';
import { Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import DomainIcon from '@mui/icons-material/Domain';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


const drawerWidth = 240;

function TrainerLayout(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [Agentopen, setAgentOpen] = React.useState(false);
  const [Bankopen, setBankopen] = React.useState(false);
  const [GSTopen, setGSTopen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />

      <List
        sx={{
          height: '100%',
        }}
        style={{ marginTop: 50 }}
      >

        {/* Home/Rollout Plan */}
        <ListItem
          onClick={() => {
            navigate("/trainerlayout/rolloutplan");
            handleDrawerToggle();
            props.setActiveList("/trainerlayout/rolloutplan");
          }}
          sx={{
            "&:hover": {
              backgroundColor: '#F6A606',
              border: '1px solid black',
              fontSize: 24
            },
          }}
          style={props.ActiveList === "/trainerlayout/rolloutplan" ? {
            backgroundColor: '#F6A606',
            border: '1px solid black',
            color: 'white',
            fontSize: 24
          } : {
            color: "white",
          }}>
          <div className={classes.back}>
            <ListItemButton>
              <ListItemIcon ><HomeIcon style={{ color: 'white', fontSize: 30 }} /></ListItemIcon>
              <Typography style={{ fontSize: 20 }} >Rollout Plan</Typography>
            </ListItemButton>
          </div>
        </ListItem>

        {/* Approved Training  */}
        <ListItem
          onClick={() => {
            navigate("/trainerlayout/approvedTrainer")
            handleDrawerToggle();
            props.setActiveList("/trainerlayout/approvedTrainer")
          }}
          sx={{
            "&:hover": {
              backgroundColor: '#F6A606',
              border: '1px solid black',
              fontSize: 24
            },
          }}
          style={props.ActiveList === "/trainerlayout/approvedTrainer" ? {
            backgroundColor: '#F6A606',
            border: '1px solid black',
            color: 'white',
            fontSize: 24
          } : {
            color: "white",
          }}>
          <div className={classes.back}>
            <ListItemButton>
              <ListItemIcon ><HomeIcon style={{ color: 'white', fontSize: 30 }} /></ListItemIcon>
              <Typography style={{ fontSize: 20 }} >Approved Training</Typography>
            </ListItemButton>
          </div>
        </ListItem>

        {/* Rejected Training  */}
        <ListItem
          onClick={() => {
            navigate("/trainerlayout/rejectedTrainer")
            handleDrawerToggle();
            props.setActiveList("/trainerlayout/rejectedTrainer")
          }}
          sx={{
            "&:hover": {
              backgroundColor: '#F6A606',
              border: '1px solid black',
              fontSize: 24
            },
          }}
          style={props.ActiveList === "/trainerlayout/rejectedTrainer" ? {
            backgroundColor: '#F6A606',
            border: '1px solid black',
            color: 'white',
            fontSize: 24
          } : {
            color: "white",
          }}

        >
          <div className={classes.back}>
            <ListItemButton>
              <ListItemIcon ><ReceiptIcon style={{ color: 'white', fontSize: 30 }} /></ListItemIcon>
              <Typography style={{ fontSize: 20 }} >Pending Documents</Typography>
            </ListItemButton>
          </div>
        </ListItem>

        {/* Add Beneficiary */}
        {/* <ListItem
          onClick={() => {
            navigate("/trainerlayout/addbeneficiary")
            handleDrawerToggle();
            props.setActiveList("/trainerlayout/addbeneficiary")
          }}
          sx={{
            "&:hover": {
              backgroundColor: '#F6A606',
              border: '1px solid black',
              fontSize: 24
            },
          }}
          style={props.ActiveList === "/trainerlayout/addbeneficiary" ? {
            backgroundColor: '#F6A606',
            border: '1px solid black',
            color: 'white',
            fontSize: 24
          } : {
            color: "white",
          }}

        >
          <div className={classes.back}>
            <ListItemButton>
              <ListItemIcon ><ReceiptIcon style={{ color: 'white', fontSize: 30 }} /></ListItemIcon>
              <Typography style={{ fontSize: 20 }} >Add Beneficiary</Typography>
            </ListItemButton>
          </div>
        </ListItem> */}

        {/* Logout */}
        <ListItem
          onClick={() => {
            props.logout()
            navigate("/")
          }}
          sx={{
            "&:hover": {
              backgroundColor: '#F6A606',
              border: '1px solid black',
              fontSize: 24
            },
          }}

          style={{ color: 'white' }}

        >
          <div className={classes.back}>
            <ListItemButton>
              <ListItemIcon ><LogoutIcon style={{ color: 'white', fontSize: 30 }} /></ListItemIcon>
              <Typography style={{ fontSize: 20 }} >LogOut</Typography>
            </ListItemButton>
          </div>
        </ListItem>
      </List>
      <Divider />
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  if (props.userDetails === null) {
    navigate("/")
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        position="fixed"
        sx={{
          position: 'fixed',
          width: { sm: `calc(100% -0px)` },
          // width: { sm: `calc(100% - ${mobileOpen ? 0 : drawerWidth}px)` },
          backgroundColor: "white",
          // border: '1px solid #3A3A56',
          zIndex: 1201,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '6px 16px',
        }}>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 1, display: { sm: "none" } }}>
            <MenuIcon />
          </IconButton>

          <img src={logo} style={{ width: 200, height: 90, margin: '0px 6px' }} />
          {/* <Typography style={{ fontSize: 30, color: 'green', fontWeight: 600 }}>GSDA</Typography> */}
        </div>

        {/* <Typography style={{
          fontSize: 30, color: 'green', fontWeight: 600, margin: '0px 5px'
        }} sx={{ display: { xs: "none", sm: 'block' } }}>Trainer Dashboard</Typography> */}

        <Typography
          style={{
            fontSize: 30,
            color: 'green',
            fontWeight: 600,
            margin: '0 auto',
            textAlign: 'center',
          }}
          sx={{ display: { xs: "none", sm: 'block' } }}
        >
          Trainer Dashboard
        </Typography>
        <img
          src={props.userDetails?.profilePicURL ? props.userDetails?.profilePicURL : Profile}
          style={{
            width: 60,
            height: 60,
            margin: '0px 6px',
            cursor: 'pointer',
            justifyContent: 'flex-end',
            border: '1px solid black',
            borderRadius: '50%',
            position: 'fixed',
            right: '10px',
            top: '10px',
            zIndex: 1202,
          }}
          onClick={() => {
            navigate('/trainerLayout/Profile');
            props.setActiveList('/trainerLayout/Profile');
          }}
        />

      </AppBar>


      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: '#3A3A56',
            },

          }}
        >
          {drawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              "::-webkit-scrollbar": {
                display: "none",
              },
              backgroundColor: '#3A3A56',
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          
        }}
      >
        <Toolbar />
        {/* <Typography paragraph> */}
        <Outlet style={{ overflow:'auto' }} />
        {/* </Typography> */}
      </Box>
    </Box>
  );
}

TrainerLayout.propTypes = {

  window: PropTypes.func,
};
const useStyles = makeStyles((theme) => ({

  back: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "180px",
    borderBottomLeftRadius: "30px",
    borderTopLeftRadius: "30px",
  },
  active: {
    backgroundColor: '#F6A606',
    border: '1px solid black',
    // fontSize: 24
  }
}));

const mapStateToProps = (state) => {
  return {
    ActiveList: state.admin.ActiveList,
    userDetails: state.auth.userDetails,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setActiveList: (data) => dispatch(Action.setActiveList(data)),
    logout: () => dispatch(Auth.logout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrainerLayout)


